<template>
    <v-app-bar class="elevation-1" color="secondary" dark fixed app>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-show="!mobileSearch"></v-app-bar-nav-icon>
        <v-progress-circular
        indeterminate
        color="white"
        size="24"
        v-show="showProgress"
        ></v-progress-circular>
            <v-toolbar-title style="margin-left:10px;" class="" v-if="!mobileSearch && !drawer && (!searchFieldState)">{{ appTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <app-toolbar-search></app-toolbar-search> <!-- search -->
        <v-toolbar-items>   
            <v-menu offset-y class="" light v-show="!mobileSearch" bottom left>
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                        <v-icon>mdi-tune</v-icon>
                    </v-btn>
                </template>
                <!--<v-list class="hidden-sm-and-down" color="grey">-->
                <v-list class="" v-show="!mobileSearch">
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon color="grey darken-3">{{ userIcon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title><span class="">{{ user.name }}</span></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <!--<v-list-item  @click="handleFullScreen()" v-show="!mobile">
                        <v-list-item-action>
                        <v-icon>mdi-fullscreen</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title><span>Fullscreen</span></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>-->
                    <v-divider></v-divider>
                    <v-list-item @click="logout">
                        <v-list-item-action>
                            <v-icon color="grey darken-3">mdi-logout</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title><span class="">Odjava</span></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-toolbar-items>
    </v-app-bar>    
</template>

<script>
import router from '@/router'
import Util from '@/helpers/utilities.js'
const AppToolbarSearch = () => import('@/views/layout/AppToolbarSearch.vue')

export default {
    name: 'app-toolbar',
    components: {
        AppToolbarSearch
    },
    data: () => ({
        //mobileSearch: false
    }),
    
    watch: {

    },

    computed: {
        listMarginLeft() {
            if(this.$vuetify.breakpoint.lgAndUp) {
                return true;
            }

            return false;
        },
        user() {
            return this.$store.getters.user
        }, 

        drawer: {
            get() {
                return this.$store.getters.drawer
            },

            set(newValue) {
                this.$store.commit('SET_DRAWER', newValue)
            }
        },

        toolbarTitle() {
            return this.$store.getters.toolbarTitle
        },

        showProgress() {
            return this.$store.getters.progress
        },

        app() {
            return this.$store.getters.app
        },

        appTitle() {
            if(this.$vuetify.breakpoint.smAndDown) {
                return this.$store.getters.app.shortTitle
            }

            return this.$store.getters.app.title
            
        },

        mobile() {
            if(this.$vuetify.breakpoint.smAndDown) {
                return true;
            }

            return false;
        },

        userIcon() {
            return 'mdi-account-circle'
        },

        searchFieldState() {
            return this.$store.getters.searchFieldState
        },

        search() {
            if(this.$store.getters.search == '' || this.$store.getters.search == null || this.$store.getters.search == undefined) {
                return false
            }

            return true
        },

        mobileSearch: {
            get() {
                return this.$store.getters.mobileSearch
            },

            set(value) {
                this.$store.commit('SET_MOBILE_SEARCH', value)                
            }

        }
    },

    methods: {
        logout() {
            this.$store.commit('SET_LOADER_TEXT', 'Odjava uporabnika je v teku...')
            this.$store.commit('SET_LOADER', true)

            this.$store.dispatch('LOGOUT')
            .then(response => {

                setTimeout(() => { 
                    this.$store.commit('SET_LOADER', false) 
                    router.push({
                        name: 'login'
                    })
                }, 800)
            })
            .catch(error =>{
                setTimeout(() => { this.$store.commit('SET_LOADER', false) }, 800),
                window.console.log('Logout error')
                window.console.log(error)

            })
        },

        hideMobileSearch() {
            this.$store.commit('SET_SEARCH', '');
            this.$store.commit('SET_MOBILE_SEARCH', false)
            //this.mobileSearch = false;
        },

        btnSearch() {
            //this.mobileSearch = !this.mobileSearch
            this.$store.commit('SET_MOBILE_SEARCH', !this.$store.getters.mobileSearch)
            setTimeout(() =>{
                this.$refs.search.focus();
            }, 500)
        },

        handleFullScreen () {
            Util.toggleFullScreen();
        }
    },

    created() {

    },

    mounted() {

    },

    destroyed() {
        
    }
}
</script>

<style>

</style>




