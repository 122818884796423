<template>
    <v-navigation-drawer
    fixed
    v-model="drawer"
    app
    width="300"
    id="appDrawer"
    >   
    <div align="center" style="margin-top:20px; margin-bottom:0px;">
        <img id="app-drawer-logo" @click="redirectToDashboard" :src="getLogo" alt="NC - Adria" width="70%" height="auto">
    </div>
    <v-divider></v-divider>
        <v-toolbar class="elevation-0 drawer-toolbar pt-2" color="white"  id="app_drawer_toolbar">
            <v-icon>mdi-earth</v-icon>
            <v-toolbar-title class="ml-0 pl-3">
                <span class="font-weight-medium">{{ app.drawerTitle }}</span>
            </v-toolbar-title>        
        </v-toolbar>
        <perfect-scrollbar class="drawer-menu--scroll">
            <v-list dense nav shaped>
                <template v-for="(item, i) in menus">
                    <!--group with subitems-->
                    <v-list-group v-if="item.items" :key="item.name" :group="item.group" :prepend-icon="item.icon" no-action="no-action">
                        <v-list-item slot="activator" ripple="ripple">
                        <v-list-item-content>
                            <v-list-item-title class="body-2">{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                        </v-list-item>
                        <template v-slot:activator>
                            <v-list-item-title class="body-2">{{ item.title }}</v-list-item-title> <!-- parent -->
                        </template>
                        <template v-for="subItem in item.items">
                            <!--sub group-->
                            <v-list-group v-if="subItem.items" :key="subItem.name"  :group="subItem.group" sub-group="sub-group" no-action="no-action"> 
                                <v-list-item slot="activator" ripple="ripple">
                                    <v-list-item-content>
                                        <v-list-item-title class="body-2">{{ subItem.title }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-for="grand in subItem.items" :key="grand.name" :to="!grand.href ? { name: grand.name} : null" :href="grand.href" ripple="ripple" >
                                    <v-list-item-content>
                                        <v-list-item-title class="body-2">{{ grand.title }}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-icon v-text="'history'"></v-icon>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list-group>
                            <!--child item-->
                            <v-list-item v-else :key="subItem.name" :to="!subItem.href ? { name: subItem.name} : null" :href="subItem.href" :disabled="subItem.disabled" :target="subItem.target" ripple="ripple" color="secondary">
                                <v-list-item-content>
                                    <v-list-item-title class="body-2"><span>{{ subItem.title }}</span></v-list-item-title>
                                </v-list-item-content>
                            <v-list-item-action v-if="subItem.action">
                                <v-icon :class="[subItem.actionClass || 'success--text']">{{ subItem.action }}</v-icon>
                            </v-list-item-action>
                            </v-list-item>
                        </template>
                    </v-list-group>
                    <v-subheader v-else-if="item.header" :key="i"><v-icon></v-icon>{{ item.header }}</v-subheader>
                    <v-divider v-else-if="item.divider" :key="i"></v-divider>
                    <!--top-level link-->
                    <v-list-item v-else :to="!item.href ? { name: item.name } : null" :href="item.href"  ripple="ripple" :disabled="item.disabled" :target="item.target" rel="noopener" :key="item.name" color="secondary">
                        <v-list-item-action v-if="item.icon">
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title class="body-2">{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action v-if="item.subAction">
                            <v-icon class="success--text">{{ item.subAction }}</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                </template>
                    <!--<v-list-item @click="logout" ripple="ripple" link>
                    <v-list-item-icon>
                        <v-icon>mdi-logout</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="body-2">Odjava</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>-->
            </v-list>
            <v-spacer></v-spacer>
        </perfect-scrollbar>
        <template v-slot:append>
        <v-divider></v-divider>
        <!--<v-list>
            <v-list-item @click="logout" ripple="ripple" link>
                <v-list-item-icon>
                    <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Odjava</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            </v-list>
            -->
            <div class="pa-4">
                <v-btn @click="logout" rounded outlined  color="secondary" block><v-icon left>mdi-logout</v-icon>Odjava</v-btn>
                </div>
    </template>
    </v-navigation-drawer>
</template>

<script>
import { Menu } from '@/helpers/menu'
import logo from '@/assets/logo.png'

export default {
    name: 'app-drawer',

    props: {
      source: String
    },

    data: () => ({
        menus: Menu,
        dialog: false,
        scrollSettings: {
            maxScrollbarLength: 260
        }
    }),
    
    watch: {

    },

    computed: {
        user() {
            return this.$store.getters.user
        },

        drawer: {
            get() {
                return this.$store.getters.drawer
            },

            set(newValue) {
                this.$store.commit('SET_DRAWER', newValue)
            }
        },

        app() {
            return this.$store.getters.app
        },

        getLogo() {
            return logo
        }
    },

    methods: {
        logout() {
            this.$store.commit('SET_LOADER_TEXT', 'Odjava uporabnika je v teku...')
            this.$store.commit('SET_LOADER', true)

            this.$store.dispatch('LOGOUT')
            .then(response => {

                setTimeout(() => { 
                    this.$store.commit('SET_LOADER', false) 
                    this.$router.push({
                        name: 'login'
                    })
                }, 800)
            })
            .catch(error =>{
                setTimeout(() => { this.$store.commit('SET_LOADER', false) }, 800),
                window.console.log('Logout error')
                window.console.log(error)

            })
        },

        redirect(path) {

        },

        genChildTarget (item, subItem) {
            if (subItem.href) return;
            if (subItem.component) {
                return {
                name: subItem.component,
                };
            }
            return { name: `${item.group}/${(subItem.name)}` };
        },

        redirectToDashboard() {
            if(this.$route.name != 'dashboard') {
                this.$router.push({ name: 'dashboard'})
            }
        }
    },

    created() {

    },

    mounted() {
        //window.console.log(this.menus)
    },

    destroyed() {

    }
}
</script>
<style>
    .drawer-toolbar {
        border-right: 1px solid rgba(0,0,0,.12) !important; 
    }

    .drawer-toolbar .v-toolbar__content {
        padding: 0 16px !important;
    }
    
    #app-drawer-logo {
        cursor: pointer !important;
        margin-top:10px;
        margin-bottom:20px;
    }

    #appDrawer {
        height:100% !important;
    }

    /*.drawer-menu--scroll {
        height: calc(100vh - 170px) !important;
        overflow: auto; 
    }

    .v-navigation-drawer__content {
        height: 100% !important;
        overflow-y: hidden ;
        overflow-x: hidden ;
    }*/


</style>




